<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="Propagation Tests" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Details" size="medium" />
            <a
              :href="
                require('../../assets/IPSeries/PropagationTestsDetails-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/PropagationTestsDetails-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light pb-6">
              The purpose of completing propagation testing is to determine
              whether a reaction from a finished or unfinished article, which
              was accidentally fired or initiated, will propagate to other
              articles or parts of the process. Articles are placed in the
              process configuration (worst case) and initiated or functioned.
              Example in-process applications include tube feeders, articles on
              a conveyor, articles in a bowl feeder, etc.
            </p>

            <TitleComponent class="py-8" title="Equivalence" size="medium" />
            <p class="p-light pb-6">
              Similar in test principle to UN Series 6 (a), 6 (b), and 6 (d),
              with the exception that articles are tested without packaging, as
              they are or may be found in the process, including during process
              upset.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Configuration of articles</td>
                    <td>
                      Simulate a specific in-process configuration (requiring
                      administrative controls to maintain) or a worst-case
                      configuration (confined, random orientation)
                    </td>
                    <td></td>
                    <td>
                      Articles placed in a worst-case configuration (e.g.
                      side-by-side, end-to-end, and/or in a pile)
                    </td>
                  </tr>
                  <tr>
                    <td>Quantity of articles</td>
                    <td>
                      Sufficient number to surround the donor and detect the
                      susceptibility for reaction propagation or the presence of
                      a mass reaction hazard
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      Confining medium (sand, loose or filled in containers) and
                      quantity
                    </td>
                    <td>
                      Provide a displaceable confining medium that resists the
                      venting of hot combustion gases
                    </td>
                    <td>Simulate worst-case processing conditions</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Ignition stimulus (device functioning)</td>
                    <td>
                      Ensure article is functioned as intended or designed (or
                      equivalent)
                    </td>
                    <td>
                      Most likely incident to be encountered during processing
                    </td>
                    <td>Cause article to function</td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Sufficient to give a reasonable level of confidence in the
                      reproducibility of the test results
                    </td>
                    <td></td>
                    <td>2 trials minimum (as possible)</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Reaction propagation between articles</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Reaction not limited to the donor only [NOT IP Division
                      1.4Q for the tested configuration].<br />
                      Significant reaction propagation (i.e. more than a few)
                      [IP Division 1.1 for the tested configuration].
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Video of Reaction Limited to Donor Only (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166205907?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166205907"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
